import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'

import Button from '../Button'
import Icon from '../Icon'

import { authSelector, logout } from '@/store/slices/authSlice'
import {
  hideOverlay,
  showOverlay,
  navigationSelector,
  showThemes
} from '@/store/slices/navigationSlice'
import { showToaster, info } from '@/store/slices/toasterSlice'
import { themes } from '@/consts'
import { isThemePath } from 'utils'

import topNavigationStyles from './topNavigationStyles.module.css'
import { Link } from '../Link/Link'

export default function TopNavigation() {
  const { asPath, push } = useRouter()
  const dispatch = useDispatch()
  const { isAuth, currentUser } = useSelector(authSelector)
  const { overlay } = useSelector(navigationSelector)
  const [menu, setMenu] = useState(false)
  const [expandThemes, setExpandThemes] = useState(false)

  const classes = {
    link: (active?: boolean) =>
      `cursor-pointer border-b-2 border-transparent hover:border-white py-1 transition-all${
        active ? ' border-white' : ''
      }`
  }

  const LinktoAboutUs = () => (
    <Link href="/eng?lang=eng">
      <Icon name="english-flag" className="relative cursor-pointer w-5 md:w-7 mx-4 z-40 " />
    </Link>
  )

  function onResizeEnd() {
    dispatch(hideOverlay())
  }

  let timeoutId: any

  function onResize() {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(onResizeEnd, 250)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <nav
      className={`flex-col top-nav container-padded h-16 transition-all relative z-30 flex items-start align-start flex-nowrap xl:w-full xl:h-24 ${
        overlay ? 'min-h-screen max-h-screen overflow-y-scroll' : 'min-h-0'
      }`}
    >
      <div className="flex w-full flex-wrap xl:flex-nowrap xl:h-full xl:items-center">
        <div className="flex items-center justify-start z-30 h-16">
          <Link href="/">
            <Icon
              name="mpe-logo-color"
              className="w-28 md:w-40 cursor-pointer"
              onClick={() => dispatch(hideOverlay())}
            />
          </Link>
        </div>
        <div className="flex-1"></div>
        <div className="cursor-pointer transition-all hover:text-skyBlue px-3 z-30 flex items-center">
          <Link href="/kereses" className="p-4">
            <Icon name="search" className="w-4 h-4" />
          </Link>
        </div>
        <div
          className={`${topNavigationStyles.menuIconContainer} flex flex-col ml-auto mt-2 items-center justify-center cursor-pointer xl:hidden`}
          onClick={() => {
            if (overlay) {
              dispatch(hideOverlay())
            } else {
              dispatch(showOverlay())
            }
          }}
        >
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <div
                key={i}
                className={`${topNavigationStyles.btnLine} transition-all bg-white rounded-full ${
                  !overlay ? '' : topNavigationStyles.closedLine
                }`}
              ></div>
            ))}
        </div>
        {/* if not full size */}
        <div className="xl:hidden my-auto">
          <LinktoAboutUs />
        </div>
        {!isAuth ? (
          <div
            className={`${topNavigationStyles.upperBlock} ${
              !overlay ? 'hidden' : 'flex'
            } flex-col w-full items-center z-30 xl:flex xl:w-auto xl:flex-row xl:items-center`}
          >
            <Icon name="user" className="w-24 h-24 mt-8 xl:hidden" />
            <Link href="/bejelentkezes">
              <span className="flex my-4 xl:my-0 xl:pr-6" onClick={() => dispatch(hideOverlay())}>
                <span className="underline cursor-pointer font-medium">Bejelentkezés</span>
              </span>
            </Link>
            <Link href="/regisztracio">
              <span className="flex my-4 xl:my-0 xl:pr-6" onClick={() => dispatch(hideOverlay())}>
                <span className="underline cursor-pointer font-medium">Regisztráció</span>
              </span>
            </Link>
          </div>
        ) : (
          <div
            className={`${topNavigationStyles.upperBlock} ${
              !overlay ? 'hidden' : 'flex'
            } flex-col w-full z-30 items-center relative cursor-pointer xl:flex xl:w-auto xl:flex-row xl:h-full`}
            onMouseOver={() => setMenu(true)}
            onMouseLeave={() => setMenu(false)}
          >
            <div className="flex flex-col items-center xl:flex-row">
              <Icon name="user" className="w-24 h-24 mt-8 xl:hidden" disableHover />
              <Icon name="user-sm" className="hidden w-8 h-8 xl:flex xl:mr-1" disableHover />
              <span className="min-w-max font-bold underline xl:mr-4">{currentUser?.name}</span>
            </div>

            <div
              className={`flex flex-col items-center transform xl:-translate-x-1/2 ${
                menu ? 'xl:absolute' : 'xl:hidden'
              } xl:bg-gradient-to-b xl:from-skyBlue xl:to-indigo xl:top-20 xl:left-5 xl:shadow-xl xl:rounded-sm xl:-mt-2`}
              onMouseOver={() => setMenu(true)}
              onMouseLeave={() => setMenu(false)}
            >
              <div className="hidden absolute -top-3 w-8 overflow-hidden xl:inline-block">
                <div className="h-4 w-4 bg-skyBlue transform rotate-45 origin-bottom-left"></div>
              </div>
              <Link
                href="/kedvencek"
                className="cursor-pointer min-w-max py-2 text-xl font-bold mt-4 xl:mt-0 xl:px-4 hover:underline"
                onClick={() => dispatch(hideOverlay())}
              >
                Kedvenceim
              </Link>
              <Link
                href="/lejatszasi-listak"
                className="cursor-pointer min-w-max py-2 text-xl font-bold xl:px-4 hover:underline"
                onClick={() => dispatch(hideOverlay())}
              >
                <span>Lejátszási listáim</span>
              </Link>
              <Link
                href="/profil"
                className="cursor-pointer min-w-max py-2 text-xl font-bold xl:px-4 hover:underline"
                onClick={() => dispatch(hideOverlay())}
              >
                Profilom
              </Link>

              <span
                className="flex cursor-pointer min-w-max py-2 text-xl font-light xl:px-4 hover:underline"
                onClick={async () => {
                  await dispatch(logout())
                  localStorage.removeItem('user_token')
                  dispatch(showToaster(info, 'Kijelentkeztél'))
                }}
              >
                Kijelentkezés
              </span>
            </div>
          </div>
        )}
        {/* only if full size */}
        <div className="hidden xl:block">
          <LinktoAboutUs />
        </div>
      </div>
      <ul
        className={`${
          !overlay ? 'hidden' : 'flex'
        } mt-5 xl:mt-0 flex-col w-full z-20 text-xl font-bold py-6 xl:h-24 xl:text-lg xl:absolute lg:right-8 xl:flex-row xl:flex xl:justify-center xl:items-center xl:font-medium`}
      >
        <li className="py-2 xl:py-0">
          <Link href="/eloado/1">
            <div
              className="flex items-center justify-center cursor-pointer xl:pr-4 xl:px-4 xl:block"
              onClick={() => setExpandThemes((et) => !et)}
              onMouseOver={() => dispatch(showThemes())}
            >
              <span className={classes.link(isThemePath(asPath))}>Katalógus</span>
              <Icon
                name="expand"
                className={`transition ml-2 w-6 h-6 xl:hidden ${
                  expandThemes ? 'transform rotate-90' : ''
                }`}
              />
            </div>
          </Link>
        </li>
        {expandThemes &&
          themes.map(({ link, title }) => (
            <li
              className="text-center cursor-pointer py-1 xl:hidden"
              key={link}
              onClick={() => {
                dispatch(hideOverlay())
                setExpandThemes(false)
              }}
            >
              <Link href={`${link}/1`}>
                <span className={`text-sm font-light ${asPath.includes(link) ? 'underline' : ''}`}>
                  {title}
                </span>
              </Link>
            </li>
          ))}
        <li className="text-center py-2 xl:py-0">
          <Link href="/galeria/1">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span className={classes.link(asPath.includes('/galeria/'))}>Galériák</span>
            </span>
          </Link>
        </li>
        <li className="text-center py-2 xl:py-0">
          <Link href="/video/1">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span className={classes.link(asPath.includes('/video/'))}>Videók</span>
            </span>
          </Link>
        </li>
        <li className="text-center py-2 xl:py-0">
          <Link href="/audio/1">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span className={classes.link(asPath.includes('/audio/'))}>Audiók</span>
            </span>
          </Link>
        </li>
        <li className="text-center py-2 xl:py-0">
          <Link href="https://bahia.popkulturalis.hu/" target="_blank" rel="noreferrer, noopener">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span>Bahia kiadó</span>
            </span>
          </Link>
        </li>
        <li className="text-center py-2 xl:py-0">
          <Link href="https://kultiq.ai/" target="_blank" rel="noreferrer, noopener">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span>KultIQ</span>
            </span>
          </Link>
        </li>
        <li className="text-center py-2 xl:py-0">
          <Link href="https://ai.popkulturalis.hu/" target="_blank" rel="noreferrer, noopener">
            <span className="xl:pr-4 xl:px-4" onClick={() => dispatch(hideOverlay())}>
              <span>MPÉ AI</span>
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
