import getConfig from 'next/config'
import { IndexWidget } from '../IndexWidget'
import { Link } from '../Link/Link'
import { useRouter } from 'next/router'

const Footer = () => {
  const config = getConfig().publicRuntimeConfig
  const router = useRouter()
  const lang = router.query.lang
  const isHomepage = router.pathname === '/' || router.asPath === `/${lang}`

  return (
    <footer className="mt-12">
      {lang === 'eng' || isHomepage ? null : <IndexWidget />}
      <div className="container">
        <div className=" flex flex-col items-center lg:justify-between py-5 lg:py-6 gap-4">
          <ul className="flex items-center justify-center md:justify-start flex-wrap md:flex-nowrap flex-row gap-4 xl:gap-6">
            <li>
              <Link href="https://popkulturalis.hu/" target="_blank" rel="noreferrer nofollow">
                <img src="/images/mpe-logo.webp" alt="MPE Logo" className="h-[30px] lg:h-[40px]" />
              </Link>
            </li>
            <li>
              <Link href="https://kultiq.ai" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/kultiq-logo.svg"
                  alt="Kultiq Logo"
                  className="h-[30px] lg:h-[40px]"
                />
              </Link>
            </li>
            <li>
              <Link href="https://kormany.hu" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/emmi_white.png"
                  alt="Kormány Logo"
                  className="h-[60px] lg:h-[80px]"
                />
              </Link>
            </li>

            <li>
              <Link href="https://petofiugynokseg.hu" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/pku-logo-2024.png"
                  className="h-[30px] lg:h-[40px]"
                  alt="Petőfi Ügynökség Logo"
                />
              </Link>
            </li>

            <li>
              <Link href="https://nka.hu" target="_blank" rel="noreferrer nofollow">
                <img src="/images/NKA2-01.png" className="h-[30px] lg:h-[40px]" alt="NKA Logo" />
              </Link>
            </li>
            <li>
              <Link href="https://kultura.hu/" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/kultura-logo.svg"
                  className="h-[30px] lg:h-[40px]"
                  alt="Kultúra.hu Logo"
                />
              </Link>
            </li>
            <li>
              <Link href="https://ailabor.appsters.me" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/jokai-logo-white.webp"
                  className="h-[30px] lg:h-[40px]"
                  alt="Jók.ai Logó"
                />
              </Link>
            </li>

            <li>
              <Link href="https://a38.hu" target="_blank" rel="noreferrer nofollow">
                <img
                  src="/images/A38_logo_white.png"
                  className="h-[30px] lg:h-[40px]"
                  alt="A38 Logo"
                />
              </Link>
            </li>
          </ul>
          <ul className="flex flex-col-reverse md:flex-row gap-4 items-center">
            <li className="flex items-center">
              <Link href={`/hivatkozasok-gyujtemenye`} className="underline text-sm">
                {lang === 'eng' ? 'References' : 'Hivatkozások gyűjteménye'}
              </Link>
            </li>
            <li className="flex items-center">
              <Link
                href={`${config.privacyPolicyPdf}`}
                locale={false}
                className="underline text-sm cursor-pointer"
                target="_blank"
              >
                {lang === 'eng' ? 'Privacy statement' : 'Adatkezelési nyilatkozat'}
              </Link>
            </li>
            <li className="flex items-center">
              <Link href={`${config.termsOfUse}`} className="underline text-sm">
                {lang === 'eng' ? 'Terms of Use' : 'Felhasználási feltételek'}
              </Link>
            </li>
            <li className="flex items-center">
              <Link href={`/impresszum`} className="underline text-sm">
                {lang === 'eng' ? 'Impressum' : 'Impresszum'}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
